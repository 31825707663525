.loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: green;
}
input:focus,
select:focus,
textarea:focus,
button:focus,
.ant-input:focus,
.ant-select-focused .ant-select-selector {
  outline: none;
  box-shadow: none; /* Also remove any box-shadow if set by default */
}

.ant-select-dropdown {
  border: 1px solid #AAAAAA;
  border-radius: 2px;
  padding: unset;
}

.ant-select-item {
  border-radius: unset;
}
.ant-picker-time-panel-container {
  padding:0 !important;
  .ant-picker-dropdown {
    padding:0;
  }
}
.ant-select-item-option-selected, .ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background-color: rgb(255, 242, 174) !important;
}