@font-face {
  font-family: "RobotoFlex";
  src: local("RobotoFlex"),
    url("../assets/fonts/RobotoFlex-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Murecho";
    src: local("Murecho"),
    url("../assets/fonts/Murecho-VariableFont_wght.ttf") format("ttf");
}

@font-face {
    font-family: "NotoSerif";
    src: local("NotoSerif"),
    url("../assets/fonts/NotoSerif-VariableFont_wdth,wght.ttf") format("ttf");
}

@font-face {
  font-family: "Superclarendon";
  src: local("Superclarendon"),
    url("../assets/fonts/Superclarendon-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Superclarendon";
  src: local("Superclarendon"),
    url("../assets/fonts/Superclarendon-Light.ttf") format("truetype");
}

@font-face {
  font-family: 'GFS Didot';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/gfs-didot-v7-greek-regular.eot');
  src: local('GFS Didot Regular'), local('GFSDidot-Regular'),
    url('../assets/fonts/gfs-didot-v7-greek-regular.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/gfs-didot-v7-greek-regular.woff2') format('woff2'),
    url('../assets/fonts/gfs-didot-v7-greek-regular.woff') format('woff'),
    url('../assets/fonts/gfs-didot-v7-greek-regular.ttf') format('truetype'),
    url('../assets/fonts/gfs-didot-v7-greek-regular.svg#GFSDidot') format('svg');
}

/* Downloaded from https://canonicalized.com/local-font/ */
/* They don't support Greek */

@font-face {
  font-family: 'Noto Serif';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/noto-serif-v6-latin-regular.eot');
  src: local('Noto Serif'), local('NotoSerif'),
    url('../assets/fonts/noto-serif-v6-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/noto-serif-v6-latin-regular.woff2') format('woff2'),
    url('../assets/fonts/noto-serif-v6-latin-regular.woff') format('woff'),
    url('../assets/fonts/noto-serif-v6-latin-regular.ttf') format('truetype'),
    url('../assets/fonts/noto-serif-v6-latin-regular.svg#NotoSerif')
      format('svg');
}

@font-face {
  font-family: 'Noto Serif';
  font-style: italic;
  font-weight: 400;
  src: url('../assets/fonts/noto-serif-v6-latin-italic.eot');
  src: local('Noto Serif Italic'), local('NotoSerif-Italic'),
    url('../assets/fonts/noto-serif-v6-latin-italic.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/noto-serif-v6-latin-italic.woff2') format('woff2'),
    url('../assets/fonts/noto-serif-v6-latin-italic.woff') format('woff'),
    url('../assets/fonts/noto-serif-v6-latin-italic.ttf') format('truetype'),
    url('../assets/fonts/noto-serif-v6-latin-italic.svg#NotoSerif')
      format('svg');
}

 /* ant design override for popups */
  .ant-picker-dropdown .ant-picker-panel-container {
     font-size: 90%;
     font-style: italic;
     appearance: none;
     transition-property: border;
     transition-duration: 0.2s;
     border: 1px solid #AAAAAA;
     border-radius: 2px;
     padding: 15px 10px;
     outline: none;
     appearance: none;
     box-shadow: none;
   }
     .ant-btn-primary {
      font-size: 90%;
        box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12),
          0 3px 1px -1px rgba(0, 0, 0, 0.2);
        text-decoration: none;
        color: #fff;
        background: #333333;
        text-align: center;
        letter-spacing: 0.5px;
        cursor: pointer;
        position: relative;
        outline: none;
        display: inline-block;
        overflow: hidden;
        user-select: none;
        vertical-align: middle;
        transition: 0.3s ease-out;
        border: none;
        box-shadow: none;
        border-radius: 2px;
        height: 36px;
        line-height: 36px;
        padding: 0 16px;
     }
 .ant-btn.ant-btn-sm {
      font-size: 90%;
        box-shadow: 0 3px 3px 0 rgb(0 0 0 / 14%), 0 1px 7px 0 rgb(0 0 0 / 12%), 0 3px 1px -1px rgb(0 0 0 / 20%);
        -webkit-text-decoration: none;
        text-decoration: none;
        color: #fff;
        background: #333333;
        text-align: center;
        -webkit-letter-spacing: 0.5px;
        -moz-letter-spacing: 0.5px;
        -ms-letter-spacing: 0.5px;
        letter-spacing: 0.5px;
        cursor: pointer;
        position: relative;
        outline: none;
        display: inline-block;
        overflow: hidden;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        vertical-align: middle;
        -webkit-transition: 0.3s ease-out;
        transition: 0.3s ease-out;
        border: none;
        border-radius: 2px;
        height: 36px;
        line-height: 36px;
        padding: 0 16px;
 }

 .ant-btn.ant-btn-sm:hover {
  background-color: #666666;
 }
.ant-btn-primary:disabled:hover {
  background: rgba(0, 0, 0, 0.04);
 }

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background: #666666;
  border-radius: 2px;
 }

 .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #666666;
  border-radius: 2px;
 }
 
 .ant-picker-dropdown .ant-picker-time-panel-column>li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  
 }




 .ant-picker-dropdown .ant-picker-today-btn {
color: #444444;
 }

 .ant-picker-dropdown .ant-picker-today-btn:hover {
  color: #666666;
 }
